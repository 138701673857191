class ClearPartOneFormFieldsComponent {
  constructor(selector) {
    // Do nothing if the selector we got isn't in the DOM
    if ($(selector).length === 0) { return; }

    // get all of the clearable fields on the page:
    const elementsToClear = ".js-clearable"
    const selectsToClear = ".js-clear-select"

    // on click of the clear button, set the value of each to ""
    $(selector).on("click", function(){
      $(elementsToClear).val("");
      $(selectsToClear).prop("selectedIndex", 0);
    });
  }
}
 export default ClearPartOneFormFieldsComponent
