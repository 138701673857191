class RatingFieldGroup {
  constructor(selector) {
    if ($(selector).length === 0) { return; }

    const ratingFieldInput =".rating-field-input"
    const ratingFieldLabel = ".rating-field-label";
    const ratingFieldDivider = ".rating-field-label__divider";

    $(ratingFieldInput).on("change", function() {
      $(this).siblings(ratingFieldLabel).addClass("highlighted")
      $(this).siblings(ratingFieldDivider).addClass("highlighted")
    });
  }
}

export default RatingFieldGroup
