class Runtime {
  constructor(selector) {
    // Do nothing if the selector we got isn't in the DOM
    if ($(selector).length === 0) { return; }

    $(selector).on("input", function(){
      let [mins, secs]  = $(this).val().split(".");

      // handle if the value returns .6 instead of .60
      if(secs.length < 2) { secs = secs + "0"; }

      // parse both into integers
      secs = parseInt(secs);
      mins = parseInt(mins);

      if(secs > 59){
        mins += 1;
        const newVal = `${mins}.00`
        $(this).val(newVal);
      }
    });
  }
}
 export default Runtime;
