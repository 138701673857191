class AssessmentAutofill {
  constructor(selector) {
    if ($(selector).length === 0) { return; }

    let timeout;
    const assessmentForm = $(".assessment-form");
    const mediaTypeField = $("#assessment_media_type");
    const mediaTitleField = $("#assessment_media_title");
    const autoCompleteList = $(".assessment-form__autocomplete-list");
    const loadingSpinner = $(".loading-spinner");
    const errorFlash = $("#new_assessment .media-title-flash");

    bindAutoCompleteSelectList();
    bindMediaTypeOnChange();
    bindMediaTitleOnChange();

    function bindMediaTypeOnChange() {
      $(mediaTypeField).on("change", function() {
        if (autofillUnavailable() && autoFillOn()) {
          let message = ""
          switch ($(this).val()) {
            case "Ancillary Content":
              message = $(this).val().toLowerCase();
              break;
            default:
              message = $(this).val().toLowerCase() + "s";
              break;
          }
          return searchFailure(`Autofill is not available for ${message} at this time.`)
        }
        errorFlash.hide();
      });
    };

    function bindMediaTitleOnChange() {
      $(mediaTitleField).on("keyup", function(){
        if (!autoFillOn() || autofillUnavailable()) {
          return;
        };

        loadingSpinner.show();
        errorFlash.hide();
        resetTimeoutAndSearchImdbList();
      });
    };

    function autofillUnavailable() {
      const disabledMediaTypes = ["Ancillary Content", "Episode", "Season"];
      return disabledMediaTypes.includes(mediaTypeField.val());
    }

    function autoFillOn() {
      return $("#autofill:checked").length > 0;
    }

    function resetTimeoutAndSearchImdbList() {
      // wait 500 milliseconds before making a request to prevent every key press making one.
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        imdbSearch("/imdb_search", listSearchParams(), renderListSearchResult);
        autoCompleteList.show();
      }, 500);
    };

    function listSearchParams() {
      return { media_type: mediaTypeField.val().toLowerCase(), query: mediaTitleField.val() };
    };

    function bindAutoCompleteSelectList() {
      autoCompleteList.on("click keyup", function(event) {
        if (event.type == "click" || event.keyCode == 32) {
          let selectedOptionId = $(this).children("option:selected").attr("data-item-id");
          assessmentForm.addClass("loading");
          mediaTitleField.prop("disabled", true);
          imdbSearch(`/imdb_search/${selectedOptionId}`, showSearchParams(), autoFillFormFields);
          autoCompleteList.hide();
        };
      });
    };

    function showSearchParams() {
      return { media_type: mediaTypeField.val().toLowerCase() };
    };

    function imdbSearch(route, params, callback) {
      $.ajax({
        url: route,
        type: 'GET',
        data: params
      })
      .done(function(data) { callback(data) })
      .fail(function(data) { searchFailure(data.responseJSON.reason) });
    };

    function searchFailure(reason) {
      loadingSpinner.hide();
      autoCompleteList.hide();
      errorFlash.html(`<p>${reason}</p>`).show();
    };

    function renderListSearchResult(results) {
      if (results.length == 0) {
        return searchFailure("There are no autofill results available for that title");
      };

      emptyAutocompleteListAndChangeSize(results.length);
      buildNewAutocompleteListOptions(results);
      loadingSpinner.hide();
    };

    function buildNewAutocompleteListOptions(results) {
      $.each(results, function(i, result) {
        autoCompleteList.append(
          `<option class="assessment-form__autocomplete-option" data-item-id="${result.id}">
            ${result.title}
           </option>`
        );
      });
    };

    function emptyAutocompleteListAndChangeSize(size) {
      autoCompleteList.empty().attr("size", size);
    };

    function autoFillFormFields(results) {
      $("#assessment_media_title").val(results.title || "");
      $("#assessment_media_alternative_title").val(results.alternative_title || "");
      $("#assessment_media_description").val(results.desription || "");
      $("#assessment_media_director").val(results.director || "");
      $("#assessment_media_language").val(results.language || "");
      $("#assessment_media_nationality").val((results.nationality || "").toUpperCase());
      $("#assessment_media_runtime").val(results.runtime || "");
      $("#assessment_media_publication_year").val(results.year_of_publication || "");
      $(mediaTitleField).prop("disabled", false);
      $(assessmentForm).removeClass("loading");
    };
  };
};

export default AssessmentAutofill
