/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context('../images', true);
import "stylesheets";
import {} from "jquery-ujs";

import "../jquery/components/header";
import RatingFieldGroup from "../jquery/components/rating-field-group";
import UserInviteConfirmationComponent from  "../jquery/components/user-invite-confirmation-component";
import CheckboxCheckLimitComponent from "../jquery/components/checkbox-check-limit-component";
import AssessmentAutofill from "../jquery/components/assessment-autofill";
import ClearPartOneFormFieldsComponent from "../jquery/components/clear-part-one-form-fields-component";
import Runtime from "../jquery/components/runtime";
import RuntimeValidation from "../jquery/components/runtime-validation";

$(document).ready(function() {
  new RatingFieldGroup(".js-rating-field-group");
  new UserInviteConfirmationComponent(".js-user-invite-confirmation-component");
  new CheckboxCheckLimitComponent(".js-checkbox-check-limit-component");
  new AssessmentAutofill(".js-assessment-autofill");
  new ClearPartOneFormFieldsComponent(".js-clear-part-one-form-fields-component");
  new Runtime(".js-runtime");
  new RuntimeValidation(".js-runtime-validation");
});
