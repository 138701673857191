class CheckboxCheckLimitComponent {
  constructor(selector) {
    // Do nothing if the selector we got isn't in the DOM
    if ($(selector).length === 0) { return; }

    $(":checkbox").click(function(){
      const $length = $(":checkbox:checked").length;
      if ( $length > 4 ) {
        $(this).prop('checked', false)
      }
    });
  }
}
 export default CheckboxCheckLimitComponent
