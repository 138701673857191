class RuntimeValidation {
  constructor(selector) {
    // Do nothing if the selector we got isn't in the DOM
    if ($(selector).length === 0) { return; }

    const errorFlash = $("#runtime-flash");
    const runtimeRegex = /^[0-9]+\.[\d]{2}$/;

    $(selector).on("blur", function(){
      if($(this).val().match(runtimeRegex)){
        return errorFlash.hide();
      }

      errorFlash.html(`<p>Runtime must be formatted minutes.seconds</p><br><p>Minutes must be zero or more and seconds must be two digits</p>`).show();
      return false;
    });
  }
}
 export default RuntimeValidation;
