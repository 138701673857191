class UserInviteConfirmationComponent {
  constructor(selector) {
    // Do nothing if the selector we got isn't in the DOM
    if ($(selector).length === 0) { return; }

    const privacyPolicyCheckboxSelector = `${selector} .js-privacy-policy`;
    const submitButtonSelector = `${selector} .js-submit-button`;

    // Disable the submit button
    if ($(`${privacyPolicyCheckboxSelector}:checked`).length == 0) {
      $(submitButtonSelector).attr("disabled", true);
    };

    $("body").on("click", privacyPolicyCheckboxSelector, function(evt) {
      // Enable the submit button if the privacy policy box is checked
      if ($(privacyPolicyCheckboxSelector).prop("checked")) {
        $(submitButtonSelector).attr("disabled", false);
      } else {
        $(submitButtonSelector).attr("disabled", true);
      }
    });
  }
}
 export default UserInviteConfirmationComponent
